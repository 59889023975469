import React, { useEffect, useRef, useState } from 'react'
import { FaAngleLeft } from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import { getChats, sendMessage } from '../../service/studentChat';
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { formatSentTime } from '../../helpers/datetime';
import { useNavigate } from 'react-router-dom';

export default function Chat(user) {
  const profileImg  = 'https://lms.stellarflux.com/static/media/userprofile.5faf09a7795d28bf5a2b.png';
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const lastMessageRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    root: chatContainerRef.current, // Use the chat container as the root
    rootMargin: "0px 0px 100% 0px", // Adjust the root margin based on your needs
  });
  const [isAtTop, setIsAtTop] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [pagevalue, setPageValue] = useState(1);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const getChatsData = async (id)=>{
    let get = await getChats(id,pagevalue)
    setMessages(get?.message?.data.reverse());
    setLastPage( get?.message.last_page);
    setPageValue(get?.message.current_page);
}
  useEffect(()=>{
getChatsData(user.user[0].id)
// Pusher.logToConsole = true;
const echo = new Echo({
  broadcaster: "pusher",
  key: "a54af538516d5957021d",
  cluster: "ap2",
  encrypted: true,
});

let isMounted = true;
echo.channel("user-send").listen("UserMessageSent", (event) => {
  try {
    if (event) {
      if (
        event?.data?.sender_id == user.user[0].id ||
        event?.data?.receiver_id == user.user[0].id
      ) {
        setMessages((prevMessages) => [...prevMessages, event.data]);
        // getstudentmessages(currentPage);
        // handleStudentlist();
      }
    }
  } catch (error) {
    console.error("Error processing event:", error);
  }
});



return () => {
  isMounted = false;
  echo.disconnect();
};
  },[user.user[0].id, currentPage])

  const getstudentmessages = async (page) => {
    try {
      // setLoading(true);
      const responce = await getChats(user.user[0].id,page)
      const reversedMessages = responce?.message?.data.reverse(); 
      setMessages((prevMessages) => [...reversedMessages, ...prevMessages]);
      setLastPage(responce?.data ? responce?.data?.last_page : "");
      setPageValue(responce?.data?.current_page);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
     getstudentmessages(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (inView && currentPage > 1) {
      getstudentmessages(currentPage - 1);
    }
  }, [inView, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const scrollTop = chatContainerRef.current.scrollTop;
        const threshold = 50;

        setIsAtTop(scrollTop < threshold);
      }
    };
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      const chatContainer = chatContainerRef.current;
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  // useEffect(() => {
  //   if (currentPage <= lastPage) {
  //     scrollToBottom();
  //   }
  // }, [messages]);
  useEffect(() => {
    if (isAtTop && currentPage < lastPage) {
      getstudentmessages(currentPage + 1);
    }
  }, [isAtTop, currentPage, lastPage]);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleSendMessage = async () => {
    if(newMessage !== ""){
      const response = await sendMessage(user.user[0].id,newMessage)
        const sentMessage = response.message.message;
        if (sentMessage?.sent_time && sentMessage?.sent_date) {
          // sentMessage.sent_time = formatSentTime(sentMessage.sent_time);
          // setMessages((prevMessages) => [...prevMessages, sentMessage]);
        } else {
          console.error("Invalid date or time in sent message:", sentMessage);
        }
        setNewMessage("");
    }
    
  };
  
  function groupedMessages() {
    // Group messages by date
    const grouped = messages.reduce((acc, message) => {
      const date = message.sent_date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});

    // Convert the grouped object into an array, sorted by date
    const sortedGroups = Object.keys(grouped)
      .sort()
      .map((date) => ({
        date,
        messages: grouped[date],
      }));
      console.log(sortedGroups);
    return sortedGroups;
  }
  function formatDate(sentDate) {
    const messageDate = new Date(sentDate);
    const today = new Date();

    if (isSameDay(messageDate, today)) {
      return "Today";
    } else if (isSameDay(messageDate, new Date(today.getTime() - 86400000))) {
      return "Yesterday";
    } else {
      return messageDate?.toLocaleDateString();
    }
  }
  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  const navigate = useNavigate()
  return (
    <>
    <div className=''>
      <div className=' p-1 bg-gray-100   rounded-lg  flex flex-row align-middle h-[8vh] w-[100%] '>
      <FaAngleLeft onClick={(()=>navigate(`/student/teacher_list?data=${localStorage.getItem('base64token')}`))} className=' text-[2rem] mt-4 mr-5' />
          {
            user.user[0].profile == null ? 
            <img className=' w-[50px] h-[50px] p-1 mt-1' src={profileImg} alt="" />
            :
            <img className=' w-[50px] h-[50px] p-1 mt-1' src={user.user[0].profile} alt="" />

          }
          <p className=' font-semibold text-lg mt-4 ml-3 '>{user.user[0].name}</p>
         
      </div>
        <div className='h-[82vh] bg-white mt-5 mx-1 overflow-auto '    ref={chatContainerRef}>
          <div className="relative h-[78vh] ">
            <ul className=''    ref={chatContainerRef}>
              {
                messages.length > 0 ?
                groupedMessages()?.map((group, groupIndex) => (
                  <div>
                    <li className='text-center my-3'>{formatDate(group.date)}</li>
                    {
                       group?.messages?.map((e, i) => (
                        e.receiver_id == user.user[0].id ?  
                        <li key={i} className=' mb-1   text-rigth flex justify-end'>
                        <span className='bg-green-300 py-2 pr-3 inline-block rounded-md pl-1  ml-[50%] leading-4'>
                        <span className='pr-6 '>  {e.message}</span><br />
                        <sub className=' text-right block py-2'>{formatSentTime(e.sent_time)}</sub>
                          </span>
                        </li>
                        :
                        <li key={i} className=' mb-1  flex justify-start '>
                        <span className='bg-gray-300 py-2 pr-3 inline-block rounded-md pl-1  mr-[50%] leading-4 '>
                        <span className='pl-3'>  {e.message}</span><br />
                        <sub className='text-left block py-2'>{formatSentTime(e.sent_time)}</sub>
                       
                          </span>
                        </li>
                       ))
                    }
                  </div>
                ))
                : null
              }
            </ul>
          </div>
            <div className="form absolute bottom-0 w-full px-1">
                <div className='flex gap-1'>
                <input
                 value={newMessage}
                 onChange={(e) => setNewMessage(e.target.value)}
          type="text"
          name="price"
          id="price"
          className="block w-full rounded-md border-0 py-1.5 pl-1 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="...."
        />
        <button  onClick={handleSendMessage} className='bg-green-400 p-2 rounded-md'>
                  send
                </button>
                </div>
                
            </div>
        </div>
      </div>
    </>
  )
}
