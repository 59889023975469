import React from 'react'
import ReactPlayer from 'react-player';


export default function Youtubeplayer({url, height}) {
  
  return (
  <>
   {
      <>
      <div className='h-[100px]'>
   <ReactPlayer
    url={`${url}&rel=0`}
    height={height ? height : "100px"}
    width="100%"
    controls
    showRelated={false}
    config={{
        youtube: {
          playerVars: { modestbranding: 1,webshare:0,showinfo:1 }
        },
      }}

  />
   </div>
      </>
   }
  </>
  )

  
  }