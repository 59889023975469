export const formatSentTime = (sentTime) =>{
    const [hours, minutes] = sentTime ? sentTime?.split(":") : "";
    const dummyDate = new Date(2000, 0, 1, hours, minutes);

    if (isNaN(dummyDate.getTime())) {
      // console.error("Invalid time string:", sentTime);
      return "Invalid Time";
    }

    const formattedTime = dummyDate?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    // console.log(formattedTime);
    return formattedTime;
  }