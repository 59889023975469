import instance from "./instance"

export const getTeacherList = async()=>{
    try{
        const get = await instance.get('/student/teacher/list')
        return {statu:"success",message:get.data}
    }catch(e){
        if(e.response.status === 401){
            return {status:"error",message:e.message}
        }
    }
}

export const sendMessage  = async(receiver_id,message)=>{
    try{
        const get = await instance.post('/student/send/chat',{
            receiver_id,message
        })
        return {statu:"success",message:get.data}
    }catch(e){
        if(e.response.status === 401){
            return {status:"error",message:e.message}
        }
    }
}
export const getChats = async(id,page)=>{
    try{
        const get = await instance.get(`/student/get/chat/${id}?page=${page}`)
        return {statu:"success",message:get.data}
    }catch(e){
        if(e.response.status === 401){
            return {status:"error",message:e.message}
        }
    } 
}