export const  base64Encode = (str)=>{
  let base64 = btoa(str);
  base64 = base64.replace(/\//g, '-');
  return base64;
}

export const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (error) {
      return false;
    }
  };