import React from 'react';
import ReactPlayer from 'react-player';

const Vimeoplayer = ({url, height}) => {
  
  return (
     <>
     {
        <ReactPlayer
          url={url}
          controls={true}
          style={{ background:"black" }}
          width='100%'
          height={height ? height : '100%'}
          playsinline
        />
     }
     </>
  );
};
export default Vimeoplayer;