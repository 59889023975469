import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { getVideoWatchTime, videoView, videoWatchTime, videoWatchTimeUpate } from '../service/videos/videos.service';
import LoginExpire from './LoginExpire';

const Vimeoplayer = ({url, height,data}) => {
  
  const [loginScreen, setLoginScreen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [timer, setTimer] = useState(0);
  const[primaryTimer,setPrimaryTimer]=useState(0)
  let interval;
  let viewvid = data.res.id;
  let moduleid = data.res.video_relation.sub_module_id;

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePlay = () => {
    setIsActive(true);
    // console.log('Video is playing');
  };

  const handlePause = () => {
    setIsActive(false);
    // console.log('Video is paused');
    clearInterval(interval)
  };

  const handleEnded = () => {
    setIsActive(false);
    // console.log('Video has ended');
    endvideo(); // Assuming you want to call endvideo when the video ends
  };
  const handleVideoClick = () => {
    if (isActive) {
      setIsActive(false);
      handlePause(); // Pause the video
    } else {
      setIsActive(true);
      handlePlay(); // Play the video
    }
  };
  const sendTime = async () => {
    const get = await getVideoWatchTime(viewvid);
    if(get.status === "error"){
      setLoginScreen(true);
    }
    setPrimaryTimer(+(get?.watchTime));
  }

  const UpdateTime = async (time) => {
    const get = await videoWatchTimeUpate(viewvid,time);
    if(get.status === "error"){
      setLoginScreen(true);
    }
    setPrimaryTimer(+(get?.watchTime));
    if(get.status === "success"){
      setTimeout(()=>{
          setTimer(0)
      },100)
  }
  }

  const endvideo = async () => {
    const get = await videoWatchTime(moduleid,viewvid);
    if(get.status === "error"){
      setLoginScreen(true);
    }
    setPrimaryTimer(+(get?.watchTime));
  }

  useEffect(() => {
    if (isActive) {
      interval = setInterval(() => {
        setTimer(prevTime => prevTime + 1); 
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => 
      clearInterval(interval);
    
  }, [isActive]);
  useEffect(() => {
    sendTime();
  }, []);

  useEffect(() => {
    if (isActive) {
    const  interval = setInterval(() => {
        UpdateTime(timer);
        // setTimer(0)
      }, 1000);

      return () => {clearInterval(interval)};
    }
  }, [isActive,timer]);





  const handleStart = async() => {
    const watch = await videoView(data.res.video_relation.sub_module_id,data.res.id);
      if(watch.status=== 'error'){
          setLoginScreen(true)
      }
    }
  return (
  
     <>
     {
      loginScreen ? <LoginExpire /> : (
        <ReactPlayer
        onStart={handleStart}
          url={url}
          controls={true}
          style={{ background:"black" }}
          width='100%'
          height={height ? height : '100%'}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          playsinline
          onClick={handleVideoClick}
        />
      )
     }
     </>

  );
};
export default Vimeoplayer;