import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function UserList(list) {
  const profileImg  = 'https://lms.stellarflux.com/static/media/userprofile.5faf09a7795d28bf5a2b.png';
    // localStorage.removeItem('teacherlist')
    // console.log(list.list)
    localStorage.setItem('teacherlist', JSON.stringify(list.list));
    const navigate = useNavigate();
  return (
    <div className='bg-gray-100'>
      <div className=' h-[60px] bg-gray-200 grid place-content-center w-full mb-5'>
          <p className=' font-semibold text-lg '>Select Teacher</p>
         
      </div>
      <div>
      {
            list.list.length > 0 ? 
              list.list.map((e,i)=>(
                <>
                <div key={i} onClick={(()=>navigate(`/student/chat?teacher=${e.id}`))}  className='bg-white shadow-lg p-1 my-2 mx-[5%] rounded-lg  flex flex-row align-middle h-[70px] w-[90%]'>
                 <div className=' mt-[5px]'>
                 {
                    e.profile != null ? 
                    <>
                    <img src={e.profile} alt="" />
                    </>
                    :<>
                    <img className=' w-[50px] h-[50px] p-1' src={profileImg} alt="" />
                    </>
                  }
                 </div>
                 <div className='mt-[16px] ml-3'>{e.name}</div>
                </div>
                </>
              ))
              :null
          }
      </div>
    </div>
  )
}
