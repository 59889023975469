import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import WithoutLogin from "./pages/WithoutLogin";
import TeacherLit from "./pages/chat/student/TeacherList";
import TeacherChat from "./pages/chat/student/TeacherChat";


function App() {



  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/without_login" element={<WithoutLogin />} />
      <Route path="/student/teacher_list" element={<TeacherLit />} />
      <Route path="/student/chat" element={<TeacherChat />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
