
import instance from "../instance";
import Cookies from "js-cookie";
export const getVideosDetail = async(id)=>{
    try{
        const get = await instance.get(`student/view-video/${id}`)
        return {statu:"success",message:get.data}
    }catch(e){
            if(e.response.status === 401){
                return {status:"error",message:e.message}
            }
    }

}

export const  videoView = async(fk_sub_module_id,fk_video_id)=>{
    try{
        const get = await instance.post(`student/create/video_watched`,{
            fk_sub_module_id,
            fk_video_id
        })
        return {statu:"success",message:get.data}
    }catch(e){
            if(e.response.status === 401){
                return {status:"error",message:e.message}
            }
    }
}


export const videoWatchTime = async(fk_sub_module_id,fk_video_id)=>{
    try{
        const get = await instance.post('student/create/video_watched',{
            fk_sub_module_id,
            fk_video_id
        })
        return {statu:"success",message:get.data}
    }catch(e){
        if(e.response.status === 401){
            return {status:"error",message:e.message}
        }
    }
}

export const videoWatchTimeUpate = async(viewvid,time)=>{
    try{
        const get = await instance.put(`student/videos/user_watch_time/${viewvid}`,{
            time
        });
        return {statu:"success",message:get.data}
    }catch(e){
        if(e.response.status === 401){
            return {status:"error",message:e.message}
        }
    }
}
export const getVideoWatchTime = async(viewvid)=>{
    try{
        const get = await instance.get(`student/videos/user_watch_time/${viewvid}`);
        return {statu:"success",message:get.data}
    }catch(e){
        if(e.response.status === 401){
            return {status:"error",message:e.message}
        }
    }
}