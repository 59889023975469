import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { videoView } from '../service/videos/videos.service';
import LoginExpire from './LoginExpire';

export default function Youtubeplayer({url, height,data}) {
  
  const [start,setStart] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  // useEffect(()=>{
  // },[setStart]);

 const handleStart = async() => {
  if(!data.res.id){
    setStart(false);
  }
  const watch = await videoView(data.res.video_relation.sub_module_id,data.res.id);
    if(watch.status=== 'error'){
        setLoginScreen(true)
    }
  }
  return (
  <>
   {
    loginScreen ? <LoginExpire /> : (
      <>
      <div className='h-[100px]'>
   <ReactPlayer
    onStart={handleStart}
    url={`${url}&rel=0`}
    height={height ? height : "100px"}
    width="100%"
    controls
    showRelated={false}
    config={{
        youtube: {
          playerVars: { modestbranding: 1,webshare:0,showinfo:1 }
        },
      }}

  />
   </div>
      </>

    )
   }
  </>
  )

  
  }