import React from 'react'

export default function LoginExpire() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-[#171717] px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">401</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Unauthorized</h1>
        </div>
      </main>
    </>
  )
}
