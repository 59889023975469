import React, { useEffect, useState } from 'react'
import { useParams,useLocation  } from "react-router-dom";
import Youtubeplayer from '../components/withoutlogin/Youtubeplayer';
import Vimeoplayer from '../components/withoutlogin/Vimeoplayer';
import LoginExpire from '../components/LoginExpire';
export default function Home() {
    const [domain, setDomain] = useState(null);
    const [loginScreen, setloginScreen] = useState(false);
    const [url, setUrl] = useState(null);
    const [videoData, setVideoData] = useState(null);
    const { search } = useLocation();
    const [height , setHeights] = useState('300px');
    const params = new URLSearchParams(search);
    const dataParam = params.get('data');
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)/;
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(video\/|channels\/|groups\/([^\/]*)\/videos\/|))(\d+)(.*)?$/;

    const apis = async()=>{
      const isValidBase64 = isBase64(dataParam)
      let decodebase = isValidBase64 ? atob(dataParam) : null;
      const parsedObject = JSON.parse(decodebase);
      console.log(parsedObject)
      if(parsedObject !== null && parsedObject.videoid){
        setVideoData(parsedObject.videoid);
        if(parsedObject?.height){
          setHeights(parsedObject.height) 
        }
      }else{
        setloginScreen(true)
      }
    }
      useEffect(()=>{
        apis();
      },[])
      useEffect(()=>{
        if(videoData !== null){
          if (youtubeRegex.test(videoData)) {
            setDomain("youtube");
          } else if (vimeoRegex.test(videoData)) {
            setDomain("vimeo");
          } 
        }
      })

  return (
    <>
    {
      loginScreen ? (
        <>
       <LoginExpire />
        </>
      ):null
    }
       {
        videoData === null ? null : (
          <>
           {
            domain === 'youtube' ? (
                <Youtubeplayer url={videoData}  height={height} />
            ) : null
        }
        {
            domain === 'vimeo' ? (
                <Vimeoplayer url={videoData}   height={height} />
            ):null
        }
          </>
        )
        
       }
    </>
  )
}

const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (error) {
      return false;
    }
  };
