import instance from "./instance";


export const setAuthToken = (token) => {
    if (token) {
      instance.defaults.headers.common['Authorization'] = 'Bearer '+token;;

    } else {
      delete instance.defaults.headers.common['Authorization']
    }
  };
