import React, { useEffect, useState } from 'react'
import { useParams,useLocation  } from "react-router-dom";
import Chat from '../../../components/chat/Chat';


export default function TeacherChat() {



  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dataParam = params.get('teacher');
  const teacherlist =  localStorage.getItem('teacherlist');
  let parseData  = JSON.parse(teacherlist);
  console.log(parseData)
   let currentTeacher  = parseData?.filter((e)=> e.id === parseInt(dataParam))

  return (
    <Chat user={currentTeacher} />
  )
}
